import axios from "axios"

const orderPrint = (data)=>{
  const params = {
    type: data.type,
    order_id: data.orderID,
    env: data.env
  }
  axios.get('/api/v1/private/account/order_pdf', {params, responseType: 'blob'}).then(res=>{
    const blob = new Blob([res.data], { type: 'application/pdf' })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = `${data.orderID}.pdf`
    link.click()
    link.remove()
  })
}

export const print = (data)=>{
  return orderPrint(data)
}