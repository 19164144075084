<template>
  <div class="container pb-5">
    <div>
      <!-- Header -->
      <div class="row">
        <div class="col-md-12 mt-5">
          <h1 class="text-center">
            Thank you for your order
          </h1>
          <br>
          <h4
            class="text-center"
            style="font-weight: 400;">
            <span v-if="confirmation.orderNumbers.length == 1">
              Your Order Number: {{confirmation.orderNumbers[0]}}
              <button
                class="btn btn-sm btn-outline-primary"
                style="padding: 0px 10px; margin-bottom: 6px;"
                @click="printPdf(confirmation.orderNumbers[0])">
                <i class="fa fa-download"/>
                Download
              </button>
            </span>
          </h4>
          <div
            v-if="confirmation.orderNumbers.length > 1"
            class="text-center">
            <h4 style="font-weight: 400;">Your Order Numbers</h4>
            <table
              class="table table-sm mt-4"
              style="width: 250px; margin: 0 auto;">
              <tbody>
                <tr
                  v-for="(o,i) in confirmation.orderNumbers"
                  :key="i">
                  <td>
                    <h4>
                      {{o}}
                      <button
                        class="btn btn-sm btn-outline-primary"
                        style="padding: 0px 10px; margin-bottom: 6px;"
                        @click="printPdf(o)">
                        <i class="fa fa-download"/>
                        Download
                      </button>
                    </h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- vShow - Show Attend Note -->
          <div
            v-if="displayShowAttendNote"
            class="alert alert-info text-center py-5 shadow-sm animate__animated animate__bounceIn">
            <div class="mb-3">
              <h4>
                <i class="fa fa-exclamation-triangle"/>&nbsp;Attention
              </h4>
            </div>
            <div>
              <strong>When you arrive in Orlando, make sure to ask the registration desk for you Official Pre-Show Order Report so that you can collect eligible spiffs and prize points during the event.</strong>
            </div>
          </div>
          <div
            class="d-grid my-3"
            style="max-width:300px; margin: 0 auto;">
            <button
              class="btn btn-primary"
              @click="goto('PrivateHome')">
              <i class="fa fa-home"/>
              Go Home
            </button>
          </div>
          <!-- Assistance -->
          <div class="row my-3">
            <div
              class="col-md-12 text-center"
              style="font-size: 1.2em;">
              <span>
                Need assistance? Contact us at&nbsp;
                <a :href="'mailto:' + email">{{email}}</a>
              </span>
            </div>
          </div>
          <!-- Rating-->
          <div
            v-if="showRating"
            class="row">
            <div class="col-md-12 text-center">
              <h3 class="d-inline-block">How was your experience today?</h3>
              <span
                class="ms-2"
                style="font-size: 1.5em;">
                <i
                  class="fa-star rating"
                  :class="rating[0].value ? 'fa gold' : 'far'"
                  @click="toggleStar(1)"/>
                <i
                  class="fa-star rating"
                  :class="rating[1].value ? 'fa gold' : 'far'"
                  @click="toggleStar(2)"/>
                <i
                  class="fa-star rating"
                  :class="rating[2].value ? 'fa gold' : 'far'"
                  @click="toggleStar(3)"/>
                <i
                  class="fa-star rating"
                  :class="rating[3].value ? 'fa gold' : 'far'"
                  @click="toggleStar(4)"/>
                <i
                  class="fa-star rating"
                  :class="rating[4].value ? 'fa gold' : 'far'"
                  @click="toggleStar(5)"/>
              </span>
            </div>
          </div>
          <!-- Feedback -->
          <div
            v-if="showForm"
            class="row"
            style="margin: 0 auto;">
            <div class="col-md-12 text-center">
              <form
                name="confirmFeedback"
                @submit.prevent="submit">
                <textarea
                  rows="5"
                  col="50"
                  class="form-control"
                  placeholder="Would you like to share more about your experience?"
                  v-model="feedback"/>
                <button
                  type="submit"
                  class="mt-2 btn btn-primary btn-sm float-end"
                  :disabled="!feedback">
                  Submit
                </button>
              </form>
            </div>
          </div>
          <!-- Feedback Confirm -->
          <div
            v-if="feedbackConfirm"
            class="row">
            <div class="col-md-12 text-center my-3">
              <h3 class="text-success animate__animated animate__bounceIn animate__faster">Thank you for your feedback!</h3>
            </div>
          </div>
        </div>
      </div>
      <!-- Order Details -->
      <div class="row">
        <div class="col-12 mt-5">
          <h4>ORDER DETAILS</h4>
          <hr>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6 col-6 my-3">
              <h5>
                <u>ORDER TOTAL</u>
              </h5>
              <span>{{numeral(orderTotal).format('$0,0.00')}}</span>
            </div>
            <div class="col-md-6 col-6 my-3">
              <h5>
                <u>PO NUMBER</u>
              </h5>
              <span>{{poNumber}}</span>
            </div>
            <div class="col-md-6 col-6 my-3">
              <h5>
                <u>ORDER DATE</u>
              </h5>
              <span>{{orderDate}}</span>
            </div>
            <div class="col-md-6 col-6 my-3">
              <h5>
                <u>ORDERED BY</u>
              </h5>
              <span>{{orderedBy}}</span>
            </div>
          </div>
        </div>
        <div class="col-md-6 my-3">
          <h5>
            <u>SHIPPING INSTRUCTIONS</u>
          </h5>
          <span>{{instructions}}</span>
        </div>
      </div>
      <hr>
      <!-- Product Summary -->
      <div v-if="confirmation.orderNumbers.length == 1">
        <h5>Product Details</h5>
        <table class="table table-sm table-striped">
          <tbody>
            <tr
              v-for="c in confirmation.orderDetails.cart.cartProducts"
              :key="c.eclipse_id">
              <td class="row">
                <div class="col-md-2 text-center">
                  <img
                    :src="c.product_data.image_url"
                    class="img-fluid"
                    style="max-height: 75px; max-width: 75px;"
                    @error="imgError">
                </div>
                <div class="col-md-10">
                  <div class="row">
                    <div class="col-md-12">
                      {{c.description}}
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-4">
                      Quantity: {{numeral(c.quantity).format('0,0')}} {{ displayUom(c.uom) }}
                    </div>
                    <div class="col-md-4">
                      Unit Price: {{ numeral(c.pricing.unit_price).format('$0,0.00') }}
                      <span
                        v-if="c.product_data.eclipse_data.price_uom == 'sf'"
                        class="text-muted">
                        /sqft
                      </span>
                    </div>
                    <div class="col-md-4">
                      Ext Price: {{ numeral(c.pricing.extended_price).format('$0,0.00') }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import comingSoon from '../../../../shared/comingSoon'
  import csContactEmail from '../../../../shared/csEmail'
  import { displayUom } from '../../../../shared/ProductFunctions'
  import { print } from '../../../../shared/print'
  
  export default {
    inject: ['env'],
    data(){
      return{
        rating: [
          {id: 1, value: false},
          {id: 2, value: false},
          {id: 3, value: false},
          {id: 4, value: false},
          {id: 5, value: false}
        ],
        feedback: null,
        feedbackConfirm: false,
        showRating: true
      }
    },
    methods:{
      goto(path){
        this.$router.push({name: path})
      },
      toggleStar(id){
        this.rating.forEach(r => r.value = false)
        let match = _.find(this.rating, {id})
        match.value = !match.value
        let lesser = _.filter(this.rating, s => s.id < id)
        lesser.forEach(l =>{
          l.value = !l.value
        })
      },
      reset(){
        this.rating.forEach(r => r.value = false)
        this.feedback = null
      },
      showFeedbackConfirm(){
        this.feedbackConfirm = true
        setTimeout(()=>{
          this.feedbackConfirm = false
        }, 3000)
      },
      submit(){
        let data = {
          feedback: this.feedback,
          rating: _.filter(this.rating, {value: true}).length
        }
        this.reset()
        this.showRating = false
        axios.post('/api/v1/private/customer_feedback/submit', data).then(res => {
          this.showFeedbackConfirm()
        })
      },
      imgError(e){
        comingSoon(e)
      },
      displayUom(uom){ return displayUom(uom) },
      printPdf(id){
        const data = {
          type: 'order',
          orderID: id,
          env: this.env
        }
        print(data)
      }
    },
    computed:{
      confirmation(){
        return this.$store.getters.confirmation
      },
      displayShowAttendNote(){
        if(this.confirmation.orderDetails.form.show_attend){
          return true
        }
      },
      showForm(){
        let ratings = this.rating.map(r => r.value)
        return _.includes(ratings, true)
      },
      orderTotal(){
        return this.confirmation.orderDetails.cart.details.cart_total
      },
      poNumber(){
        return this.confirmation.orderDetails.form.poNumber || 'N/A'
      },
      instructions(){
        return this.confirmation.orderDetails.form.instructions || 'N/A'
      },
      orderDate(){
        return this.confirmation.completedDate
      },
      orderedBy(){
        return this.confirmation.orderDetails.form.orderedBy
      },
      email(){return csContactEmail(this.env)}
    }
  }
</script>

<style scoped>
  .gold{
    color: gold;
    text-shadow: 0px 0px 2px black;
    transition: .2s ease-in-out;
  }
  .far{
    transition: .2s ease-in-out;
  }
  .rating:hover{
    cursor:pointer;
  }
</style>