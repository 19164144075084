<template>
  <div class="animate__animated animate__fadeIn animate__faster">
    
      <div class="row">
        <div class="col-md-12">
          <button
            class="float-start btn btn-primary btn-sm"
            @click="back">
            <i class="fa fa-chevron-left"></i>
            &nbsp;Back
          </button>
          <button
            v-if="userRoles.includes(user.role)"
            class="float-end btn btn-primary btn-sm"
            data-bs-toggle="modal"
            data-bs-target="#cartCopyMoveModal"
            @click="setCopyInvoice">
            <i class="fa fa-copy"/>
            Copy as New Order
          </button>
          <button
            class="btn btn-primary btn-sm float-end mx-2 d-none d-lg-block"
            @click="printPdf">
            <i class="fa fa-download"/>
            Download
          </button>
        </div>
      </div>
       <!-- Keeps header info visible on mobile scroll -->
      <ScrolledHeader :showScrolledHeader="showScrolledHeader" :invoice="invoice" />

      <div v-if="invoice.details">
        <!-- Header -->
        <div
          ref="header"
          class="p-3 my-2 border rounded-3">
          <div class="row">
            <div class="col-md-12">
              <h4>{{invoice.details.id + '.' + invoice.details.invoice_number}}</h4>
            </div>
            <!-- Details -->
            <div class="col-auto col-md-4">
              <ul class="list-unstyled mx-0" >
                <li>Description: {{invoice.details.description}}</li>
                <li>Branch: {{invoice.details.branch_id}}</li>
                <li>City: {{invoice.details.city}}</li>
                <li>PO Number: {{invoice.details.customer_po}}</li>
              </ul>
            </div>
            <div class="col-auto col-md-4">
              <ul class="list-unstyled mx-0" >
                <li>Release Number: {{invoice.details.release_number}}</li>
                <li>Ship Via: {{invoice.details.ship_via}}</li>
                <li>Shipping Branch: {{invoice.details.shipping_branch}}</li>
              </ul>
            </div>
            <div class="col-auto col-md-4">
              <ul class="list-unstyled mx-0" >
                <li>Order Date: {{invoice.details.order_date}}</li>
                <li>Ship Date: {{invoice.details.ship_date}}</li>
                <li>Invoice Due Date: {{invoice.details.invoice_due_date}}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <!-- Addresses -->
          <div class="row">
            <!-- Ship To -->
            <div class="col-md-6 mb-2">
              <div
                class="p-3 border rounded-3 h-100">
                <b>Ship To</b>
                <ul class="list-unstyled m-0">
                  <li>({{invoice.details.ship_to_id}}) {{invoice.details.ship_to_name}}</li>
                  <li>Address: {{shipTo.street_line_1}}</li>
                  <li v-if="shipTo.street_line_2.length > 0">Address Line 2: {{shipTo.street_line_2}}</li>
                  <li>City: {{shipTo.city}}</li>
                  <li>State: {{shipTo.state}}</li>
                  <li>Zip: {{shipTo.postal_code}}</li>
                  <li>Country: {{shipTo.country}}</li>
                </ul>
              </div>
            </div>
            <!-- Bill To -->
            <div class="col-md-6 mb-2">
              <div 
                class="p-3 border rounded-3 h-100">
                <b>Bill To</b>
                <ul class="list-unstyled m-0">
                  <li>({{invoice.details.bill_to_id}}) {{invoice.details.bill_to_name}}</li>
                  <li>Address: {{billTo.street_line_1}}</li>
                  <li v-if="billTo.street_line_2.length > 0">Address Line 2: {{billTo.street_line_2}}</li>
                  <li>City: {{billTo.city}}</li>
                  <li>State: {{billTo.state}}</li>
                  <li>Zip: {{billTo.postal_code}}</li>
                  <li>Country: {{billTo.country}}</li>
                </ul>
              </div>
            </div>
          </div>
          <!-- Sales Staff Data Visible -->
          <div
            v-if="internalRoles.includes(user.role)"
            class="row">
            <!-- Shipping Instructions -->
            <div class="col-md-6 mb-2">
              <div
                class="p-3 border rounded-3 h-100">
                <b>Shipping Instructions</b>
                <ul class="list-unstyled m-0">
                  <li>{{invoice.details.shipping_instructions || 'N/A'}}</li>
                </ul>
              </div>
            </div>
            <!-- Internal Notes -->
            <div class="col-md-6 mb-2">
              <div
                class="p-3 border rounded-3 h-100">
                <b>Internal Notes</b>
                <ul class="list-unstyled m-0">
                  <li>{{invoice.details.internal_notes || 'N/A'}}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Items -->
       <LineItem v-for="p in products" :product="p" type="invoice" :invoice="invoice" :key="p.eclipse_id" />  

        <!-- Totals -->
        <div class="row p-2 text-muted">
          <div class="col-md-12">
            <div class="float-end">
              <table class="table table-sm">
                <tbody>
                  <!-- Amount -->
                  <tr>
                    <td>Amount</td>
                    <td>{{numeral(currencyConvert(invoice.details.subtotal)).format('$0,0.00')}}</td>
                  </tr>
                  <tr v-if="invoice.details.freight">
                    <td>Freight</td>
                    <td>{{numeral(currencyConvert(invoice.details.freight)).format('$0,0.00')}}</td>
                  </tr>
                  <tr v-if="invoice.details.handling">
                    <td>Handling</td>
                    <td>{{numeral(currencyConvert(invoice.details.handling)).format('$0,0.00')}}</td>
                  </tr>
                  <tr v-if="invoice.details.tax">
                    <td>Tax</td>
                    <td>{{numeral(currencyConvert(invoice.details.tax)).format('$0,0.00')}}</td>
                  </tr>
                  <tr v-if="invoice.details.lastPaid">
                    <td>Paid</td>
                    <td>{{numeral(currencyConvert(invoice.details.lastPaid)).format('$0,0.00')}}</td>
                  </tr>
                  <tr v-if="invoice.details.lastPaidDate">
                    <td>Pay Date</td>
                    <td>{{moment(invoice.details.lastPaidDate).format('MM-DD-YYYY')}}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td>Outstanding</td>
                    <td>{{numeral(currencyConvert(invoice.details.total)).format('$0,0.00')}}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="row">
        <div class="col-md-12 text-center text-muted my-5">
          <h4>No Invoice Detail Available</h4>
          <small>Please contact our Accounting Department to inquire</small>
          <br>
          <a href="mail_to: accounting@lancasterco.com">accounting@lancasterco.com</a>
        </div>
      </div>
    
  </div>
</template>

<script>
  import {currencyConverter} from '../../../../shared/currencyConverter'
  import comingSoon from '../../../../shared/comingSoon'
  import ScrolledHeader from './ScrolledHeader.vue'
  import LineItem from './LineItem.vue'
  import { userRoles, internalRoles } from '../../../../shared/roles'
  import { print } from '../../../../shared/print'

  export default {
    props: ['invoice'],
    inject: ['env'],
    data(){
      return{
        placeholder: require('../../../../../assets/images/coming_soon.png'),
        showScrolledHeader: false,
        userRoles,
        internalRoles
      }
    },
    components:{
      ScrolledHeader,
      LineItem
    },
    mounted(){
      if(this.$refs.header){
        let observer = new IntersectionObserver(this.handleIntersection)
        observer.observe(this.$refs.header)
        this.observer = observer
      }
    },
    destroyed() {
      this.observer.disconnect();
    },
    methods:{
      back(){this.$emit('back')},
      setActiveProduct(product){this.$store.dispatch('setActiveProduct', product)},
      currencyConvert(amount){
        if(this.invoice.details.foreign_currency == 'CAD'){
          return currencyConverter(this.invoice.details.exchange_rate, amount)
        }else{
          return amount
        }
      },
      imgError(e){
        comingSoon(e)
      },
      handleIntersection(entries, observer){
        for (let entry of entries) {
          if (!entry.isIntersecting){
            this.showScrolledHeader = true
          } else {
            this.showScrolledHeader = false
          }
        }
      },
      setCopyInvoice(){
        this.$store.dispatch('setCopyInvoice', this.invoice)
      },
      printPdf(){
        const data = {
          type: 'invoice',
          orderID: this.invoice.details.id + '.' + this.invoice.details.invoice_number,
          env: this.env
        }
        print(data)
      }
    },
    computed:{
      shipTo(){return this.invoice.details.ship_to_address},
      billTo(){return this.invoice.details.bill_to_address},
      products(){
        this.invoice.details.line_items.forEach(function(l){
          l.product_detail = _.find(this.invoice.products, p => p.product_id == l.eclipse_id)
        }.bind(this))
        return this.invoice.details.line_items
      },
      user(){return this.$store.getters.user}
    }
  }
</script>

<style scoped>
/* .product {outline:2px solid blue}
.product *{outline:1px solid red;} */
  .hover:hover{
    cursor: pointer;
    transform: scale(1.025);
    transition: .25s ease-in-out;
  }
  
</style>