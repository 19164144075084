<template>
  <div class="row">
    <div class="col-md-12 ps-4 pt-2">
      <ul class="p-0 nav-list">
        <li>
          <a
            class="nav-link"
            :class="{active: activeRoute == '/'}"
            href="javascript: void(0);"
            @click="goto('PrivateHome')">
            <i class="fa fa-home"/>
            Home
          </a>
        </li>
      </ul>
      <!-- Start My Order Section -->
      <div class="nav-section">
        <strong class="heading">
          START MY ORDER
          <i
            class="fa fa-caret-down float-end"
            style="position: relative; top: 6px;"/>
        </strong>
        <ul
          class="p-0 nav-list"
          id="start">
          <li v-if="settings.shopByCategory">
            <a
              class="nav-link"
              :class="{active: activeRoute == '/browse' || query && ['browseMain', 'browseSub'].includes(query.action)}"
              href="javascript: void(0);"
              @click="goto('BrowseProducts')">
              Shop By Category
            </a>
          </li>
          <li v-if="settings.shopByBrand">
            <a
              class="nav-link"
              :class="{active: activeRoute == '/browse_brands' || query && query.action == 'browseBrand'}"
              href="javascript: void(0);"
              @click="goto('BrowseBrands')">
              Shop By Brand
            </a>
          </li>
          <li v-if="settings.closeoutDeals">
            <router-link
              class="nav-link"
              :class="{active: activeRoute == '/closeouts' || query && query.action == 'clearance'}"
              :to="env == 'EJW' ? {name: 'BrowseClearance'} : {name: 'ProductSearch', query: {action: 'clearance'}}">
              Clearance
            </router-link>
          </li>
          <li v-if="settings.newProducts">
            <router-link
              class="nav-link"
              :class="{active: query && query.action == 'new'}"
              :to="{name: 'ProductSearch', query: {action: 'new'}}">
              New Products
            </router-link>
          </li>
          <li v-if="settings.promotions && promoReceive">
            <a
              class="nav-link"
              :class="{active: (query && ['browsePromo', 'browseAllPromos'].includes(query.action)) || activeRoute == '/promos'}"
              href="javascript: void(0);"
              @click="goto('Promos')">
              Promotions
            </a>
          </li>
          <li v-if="settings.orderingTools">
            <router-link
              class="nav-link"
              :class="{active: activeRoute == '/quick_pad'}"
              :to="{name: 'QuickPad'}">
              Quick Pad
            </router-link>
          </li>
          <li v-if="settings.orderingTools">
            <router-link
              class="nav-link"
              :class="{active: query && query.action == 'reorderPad'}"
              :to="{name: 'ProductSearch', query:{action: 'reorderPad'}}">
              Reorder Pad
            </router-link>
          </li>
          <li v-if="settings.orderingTools && !internalRoles.includes(user.role)">
            <router-link
              class="nav-link"
              :class="{active: activeRoute == '/favorites'}"
              :to="{name: 'Favorites'}">
              Favorites
            </router-link>
          </li>
          <li v-if="vShowAllowed">
            <a
              class="nav-link position-relative"
              :class="{active: activeRoute == '/virtual_show'}"
              href="javascript: void(0);"
              @click="goto('VirtualShow')">
                Florida Show
            </a>
          </li>
        </ul>
      </div>
      <div
        v-if="showResources"
        class="nav-section">
        <div
          class="resources"
          data-bs-toggle="collapse"
          data-bs-target="#resources">
          <strong class="heading">
            RESOURCES
            <i
              class="fa fa-caret-down float-end"
              style="position: relative; top: 6px;"/>
          </strong>
        </div>
        <ul
          class="p-0 nav-list collapse"
          id="resources">
          <li v-if="settings.privateLabel">
            <a
              class="nav-link"
              :class="{active: activeRoute == '/dynamic_merit_pro'}"
              href="javascript: void(0);"
              @click="goto('DynamicMeritPro')">
              Dynamic &amp; Merit Pro
            </a>
          </li>
          <li v-if="settings.events">
            <a
              class="nav-link"
              :class="{active: activeRoute == '/events'}"
              href="javascript: void(0);"
              @click="goto('PrivateEvents')">
              Events
            </a>
          </li>
          <li v-if="settings.catalogs">
            <a
              class="nav-link"
              :class="{active: activeRoute == '/catalogs'}"
              href="javascript: void(0);"
              @click="goto('Catalogs')">
              Catalogs
            </a>
          </li>
          <!-- Lancaster Canada Customers do not have magazines -->
          <li v-if="sessionCompany != 'Lancaster Canada' && settings.magazines">
            <a
              class="nav-link"
              :class="{active: activeRoute == '/magazines'}"
              href="javascript: void(0);"
              @click="goto('Magazines')">
              Magazines
            </a>
          </li>
          <li v-if="settings.library">
            <a
              class="nav-link"
              :class="{active: activeRoute == '/library'}"
              href="javascript: void(0);"
              @click="goto('Library')">
              Library
            </a>
          </li>
          <li v-if="internalRoles.includes(user.role)">
            <a
              class="nav-link"
              href="https://employees.lancasterco.com"
              target="_blank">
              Employee Site
            </a>
          </li>
          <li>
            <a
              class="nav-link"
              :class="{active: activeRoute == '/contact'}"
              href="javascript: void(0);"
              @click="goto('PrivateContact')">
              Contact {{ name }}
            </a>
          </li>
          <li>
            <a
              class="nav-link"
              :class="{active: activeRoute == '/policies'}"
              href="javascript: void(0);"
              @click="goto('Policies')">
              Policies & Procedures
            </a>
          </li>
          <li>
            <a
              class="nav-link"
              :class="{active: activeRoute == '/privacy_policy'}"
              href="javascript: void(0);"
              @click="goto('PrivatePrivacyPolicy')">
              Privacy Policy
            </a>
          </li>
        </ul>
      </div>
      <!-- Links -->
      <div class="mt-2">
        <ul class="p-0 nav-list">
          <!-- Cross Sell -->
          <li v-if="sessionCompany != 'Lancaster Canada'">
            <a
              class="nav-link"
              href="javascript: void(0);"
              data-bs-toggle="modal"
              data-bs-target="#crossSellModal">
              Become {{ crossSellCompany }} Customer
            </a>
          </li>
          <li>
            <a
              class="nav-link"
              :class="{active: activeRoute == '/patch_notes'}"
              href="javascript: void(0);"
              @click="goto('PatchNotes')">
              Latest Updates
            </a>
          </li>
          <li>
            <a
              class="nav-link"
              :class="{active: activeRoute == '/faq'}"
              href="javascript: void(0);"
              @click="goto('Faq')">
              Help & FAQs
            </a>
          </li>
          <li>
            <a
              class="nav-link"
              href="javascript: void(0);"
              @click="logOut">
              Log Out
            </a>
          </li>
        </ul>
        <!-- EJW Links -->
        <div v-if="env.includes('EJW')">
          <a
            :href="env == 'EJW-CHENEY' ? 'https://www.facebook.com/people/Cheney-Vrooman/61568796460254/?_rdr' : 'https://www.facebook.com/ejwelchco'"
            target="_blank"
            class="text-primary">
            <i class="fab fa-facebook fa-2x px-2"/>
          </a>
          <a
            href="https://www.linkedin.com/company/1170922/"
            target="_blank"
            class="text-primary">
            <i class="fab fa-linkedin fa-2x px-2"/>
          </a>
          <a
            :href="env == 'EJW-CHENEY' ? 'https://www.instagram.com/cheney.vrooman/' : 'https://www.instagram.com/ejwelchco/'"
            target="_blank"
            class="text-primary">
            <i class="fab fa-instagram fa-2x px-2"/>
          </a>
        </div>
        <!-- Lancaster Links -->
        <div v-else>
          <a
            href="https://www.linkedin.com/company/lancaster"
            target="_blank"
            class="text-primary">
            <i class="fab fa-linkedin fa-2x px-2"/>
          </a>
          <a
            href="https://www.youtube.com/channel/UCTMaC8Dsj6macmcvGZqOrAQ"
            target="_blank"
            class="text-primary">
            <i class="fab fa-youtube fa-2x px-2"/>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {companyName} from '../../../../shared/env'
  import { internalRoles } from '../../../../shared/roles';
  export default{
    inject:['env'],
    data(){
      return{
        internalRoles,
        crossSellCompany: this.env.includes('EJW') ? 'a Lancaster' : 'an EJ Welch',
        vShowTitle: 'Virtual Show'
      }
    },
    watch:{
      vShowData(newData){
        if(newData){
          this.vShowTitle = newData.title
        }
      }
    },
    methods:{
      goto(view){
        this.$router.push({name: view})
        this.$store.dispatch('closeLeftMenu')
      },
      logOut() {
        this.$store.dispatch('eclipseLogOut')
      }
    },
    computed:{
      route(){return this.$router.currentRoute.value},
      activeRoute(){return this.route.path},
      query(){return this.route.query},
      settings(){return this.$store.getters.settings},
      promoReceive(){return this.$store.getters.promoReceive},
      vShowAllowed(){return this.settings.virtualShow && this.$store.getters.vShowAllowed},
      vShowData(){
        if(this.vShowAllowed){
          return this.$store.getters.vShowDates
        }
        return null
      },
      sessionCompany(){return this.$store.getters.sessionCompany},
      showResources(){
        if(this.settings.events || this.settings.magazines || this.settings.catalogs || this.settings.library) return true
        return false
      },
      name(){return companyName(this.env)},
      user(){return this.$store.getters.user}
    }
  }
</script>

<style scoped>
  .nav-link{
    padding: 0px;
    color: #777;
  }
  .nav-link:hover{
    color: var(--bs-link-hover-color);
    font-weight: 600;
    transition: 200ms;
  }
  .nav-list{
    list-style-type: none;
    font-weight: 500;
    line-height: 1.7em;
    font-size:15px;
    margin-bottom: 10px;
  }
  .nav-section{
    border-bottom: 1px solid var(--bs-primary);
  }
  .active{
    color: var(--bs-link-hover-color);
    font-weight: 600;
  }
  .heading{
    font-size:12px;
  }
  :focus{
    color: var(--bs-link-hover-color) !important;
  }
  .resources:hover{
    transform: scale(1.05);
    cursor: pointer;
  }
</style>
