<template>
  <div class="animate__animated animate__fadeIn animate__faster order-margin">
      <div class="row">
        <div class="col-md-12">
          <button class="btn btn-primary btn-sm" @click="back">
            <i class="fa fa-chevron-left" />
            &nbsp;Back
          </button>
          <button
            class="btn btn-primary btn-sm float-end mx-2 d-none d-lg-block"
            @click="printPdf">
            <i class="fa fa-download"/>
            Download
          </button>
        </div>
      </div>
      <!-- Keeps header info visible on mobile scroll -->
      <ScrolledHeader :showScrolledHeader="showScrolledHeader" :order="order" />
      <!-- Header -->
      <div ref="header" class="p-3 my-2 border rounded-3">
        <div class="row">
          <div class="col-md-12">
            <h4>{{order.details.id + '.' + order.details.invoice_number}}</h4>
          </div>
          <!-- Details -->
          <div class="col-auto col-md-4">
            <ul class="list-unstyled px-0 mx-0">
              <li><span class="text-primary">Description:</span> {{order.details.description}}</li>
              <li><span class="text-primary">Branch:</span> {{order.details.branch_id}}</li>
              <li><span class="text-primary">City:</span> {{order.details.city}}</li>
              <li><span class="text-primary">PO Number:</span>
                {{order.details.customer_po ? order.details.customer_po : 'None'}}</li>
            </ul>
          </div>
          <div class="col-auto col-md-4">
            <ul class="list-unstyled px-0 mx-0">
              <li><span class="text-primary">Release Number:</span> {{order.details.release_number}}</li>
              <li><span class="text-primary">Ship Via:</span> {{order.details.ship_via}}</li>
              <li><span class="text-primary">Shipping Branch:</span> {{order.details.shipping_branch}}</li>
            </ul>
          </div>
          <div class="col-auto col-md-4">
            <ul class="list-unstyled px-0 mx-0">
              <li><span class="text-primary">Order Date:</span> {{order.details.order_date}}</li>
              <li><span class="text-primary">Ship Date:</span> {{order.details.ship_date}}</li>
              <li><span class="text-primary">Invoice Due Date:</span> {{order.details.invoice_due_date}}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <!-- Addresses -->
        <div class="row">
          <!-- Ship To -->
          <div class="col-md-6 mb-2">
            <div class="p-3 border rounded-3 h-100">
              <b>Ship To</b>
              <ul class="list-unstyled m-0">
                <li>({{order.details.ship_to_id}}) {{order.details.ship_to_name}}</li>
                <li>Address: {{shipTo.street_line_1}}</li>
                <li v-if="shipTo.street_line_2.length > 0">Address Line 2: {{shipTo.street_line_2}}</li>
                <li>City: {{shipTo.city}}</li>
                <li>State: {{shipTo.state}}</li>
                <li>Zip: {{shipTo.postal_code}}</li>
                <li>Country: {{shipTo.country}}</li>
              </ul>
            </div>
          </div>
          <!-- Bill To -->
          <div class="col-md-6 mb-2">
            <div class="p-3 border rounded-3 h-100">
              <b>Bill To</b>
              <ul class="list-unstyled m-0">
                <li>({{order.details.bill_to_id}}) {{order.details.bill_to_name}}</li>
                <li>Address: {{billTo.street_line_1}}</li>
                <li v-if="billTo.street_line_2.length > 0">Address Line 2: {{billTo.street_line_2}}</li>
                <li>City: {{billTo.city}}</li>
                <li>State: {{billTo.state}}</li>
                <li>Zip: {{billTo.postal_code}}</li>
                <li>Country: {{billTo.country}}</li>
              </ul>
            </div>
          </div>
        </div>
        <div
          v-if="internalRoles.includes(user.role)"
          class="row">
          <!-- Shipping Instructions -->
          <div class="col-md-6 mb-2">
            <div
              class="p-3 border rounded-3 h-100">
              <b>Shipping Instructions</b>
              <ul class="list-unstyled m-0">
                <li>{{order.details.shipping_instructions || 'N/A'}}</li>
              </ul>
            </div>
          </div>
          <!-- Internal Notes -->
          <div class="col-md-6 mb-2">
            <div
              class="p-3 border rounded-3 h-100">
              <b>Internal Notes</b>
              <ul class="list-unstyled m-0">
                <li>{{order.details.internal_notes || 'N/A'}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      
      <!-- Items -->
      <LineItem
        v-for="p in products"
        :product="p"
        type="order"
        :key="p.eclipse_id"
        :order="order"/>  

      <!-- Totals -->
      <div class="p-2 text-muted">
        <div class="col-md-12">
          <div class="float-end">
            <div>
              <b>
                <span class="float-start">
                  SubTotal:&nbsp;
                </span>
                <span class="float-end">
                  {{numeral(currencyConvert(order.details.subtotal)).format('$0,0.00')}}
                </span>
              </b>
            </div>
            <span class="clearfix" />
            <div>
              <b>
                <span class="float-start">
                  Freight:&nbsp;
                </span>
                <span class="float-end">
                  {{numeral(currencyConvert(order.details.freight)).format('$0,0.00')}}
                </span>
              </b>
            </div>
            <span class="clearfix" />
            <div>
              <b>
                <span class="float-start">
                  Handling:&nbsp;
                </span>
                <span class="float-end">
                  {{numeral(currencyConvert(order.details.handling)).format('$0,0.00')}}
                </span>
              </b>
            </div>
            <span class="clearfix" />
            <div>
              <b>
                <span class="float-start">
                  Tax:&nbsp;
                </span>
                <span class="float-end">
                  {{numeral(currencyConvert(order.details.tax)).format('$0,0.00')}}
                </span>
              </b>
            </div>
            <span class="clearfix" />
            <div style="border-top:1px solid lightgray;">
              <b>
                <span class="float-start">
                  Total:&nbsp;
                </span>
                <span class="float-end">
                  {{numeral(currencyConvert(order.details.total)).format('$0,0.00')}}
                </span>
              </b>
            </div>
          </div>
        </div>
      </div>
    
  </div>
</template>

<script>
import ScrolledHeader from './ScrolledHeader.vue'
import LineItem from './LineItem.vue'
import { internalRoles } from '../../../../shared/roles'
import { currencyConverter } from '../../../../shared/currencyConverter'
import { print } from '../../../../shared/print'

  export default {
    props: ['order'],
    inject: ['env'],
    data(){
      return {
        showScrolledHeader: false,
        internalRoles
      }
    },
    components:{
      ScrolledHeader,
      LineItem
    },
    mounted(){
      if(this.$refs.header){
        let observer = new IntersectionObserver(this.handleIntersection)
        observer.observe(this.$refs.header)
        this.observer = observer
      }
    },
    destroyed() {
      this.observer.disconnect();
    },
    methods:{
      back(){this.$emit('back')},
      handleIntersection(entries, observer){
        for (let entry of entries) {
          if (!entry.isIntersecting){
            this.showScrolledHeader = true
          } else {
            this.showScrolledHeader = false
          }
        }
      },
      currencyConvert(amount){
        if(this.order?.details.exchange_rate){
          const rate = this.order.details.exchange_rate
          return currencyConverter(rate, amount)
        }else{
          return amount
        }
      },
      printPdf(){
        const data = {
          type: 'order',
          orderID: this.order.details.id,
          env: this.env
        }
        print(data)
      }
    },
    computed:{
      shipTo(){return this.order.details.ship_to_address},
      billTo(){return this.order.details.bill_to_address},
      products(){
        this.order.details.line_items.forEach(function(l){
          l.product_detail = _.find(this.order.products, p => p.product_id == l.eclipse_id)
        }.bind(this))
        return this.order.details.line_items
      },
      user(){return this.$store.getters.user}
    }
  }
</script>

<style scoped>
  .hover:hover{
    cursor: pointer;
    transform: scale(1.025);
    transition: .25s ease-in-out;
  }
  .order-margin{
    margin-bottom: 100px;
  }
</style>